import React, { useState } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "components/Layout/Layout";
import ContactUsContents from "components/ContactUsContents/ContactUsContents";
import SupportForm from "components/SupportForm/SupportForm";
import PageContext from "contexts/PageContext";
import SupportLandingSection from "components/SupportLanding/SupportLandingSection";
import {
  AYANA_JAKARTA_ID,
  JAKARTA,
  JAKARTA_LOCATION,
} from "store/constants/hotel";
import ReachUs from "components/ReachUs/ReachUs";
import useQueryParams from "hooks/useQueryParams";
import { whatsAppUrl } from "utils/whatsAppUrl";
import { transformObject } from "utils/transformv4";

const Support = ({ data }) => {
  const { reason, destination } = useQueryParams();
  const { t } = useTranslation(["homepage", "support"]);
  const [supportReason, setSupportReason] = useState(reason);
  const {
    cms,
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const {
    hotelsByLocale: [hotel],
    supportDrawer: [supportDrawer],
    supportRecipientEmails: recipientEmail,
    destinationImagesSections,
    lvxLogo: [lvxLogo],
  } = transformObject(cms);

  const supportDrawerData = {
    ...supportDrawer,
    hotel: hotel,
  };

  const reachUsLink = whatsAppUrl(hotel.whatsapp_contact, hotel.custom_whatsapp_message);

  return (
    <PageContext.Provider
      value={{
        showBannerPadding: true,
        location: JAKARTA_LOCATION,
      }}>
      <Layout
        destination={t("Jakarta")}
        seoTitle={t("Ayana Support")}
        destinationCards={destinationImagesSections[0].images}
        banner={true}
        showBanner={true}
        navigations={navigations}
        hotel={hotel}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with Jakarta")}
        supportDrawer={supportDrawerData}
        whatsAppLink={reachUsLink}
        lvxLogo={hotel.id === AYANA_JAKARTA_ID && lvxLogo}>
        <SupportLandingSection
          title={t("Ayana Support")}
          contactUsContents={<ContactUsContents hotels={hotel.offices} />}
          otherContents={
            <ReachUs supportReachUsLink={reachUsLink} />
          }
          supportForm={
            <SupportForm
              reason={reason}
              setReason={setSupportReason}
              destination={destination}
              navigateBackTo="/jakarta/ayana-midplaza-jakarta/thank-you"
              location={JAKARTA}
              recipients={recipientEmail}
            />
          }
        />
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query JakartaSupport($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNavigationMenuJson(
      filter: { hotel: { eq: "AYANA MIDPLAZA, JAKARTA" } }
    ) {
      ...LocalNavigation
    }
    allSocialMediaJson(filter: { hotel: { eq: "AYANA MIDPLAZA, JAKARTA" } }) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }
    cms {
      hotelsByLocale(language: $language, where: { id: 7 }) {
        data {
          id
          attributes {
            title
            email
            phone
            name
            whatsapp_contact
            custom_whatsapp_message
            location
            offices(sort: "sequence") {
              ...CmsOffices_v4
            }
            ...CmsHotelLogo_v4
          }
        }
      }
      destinationImagesSections: sections(filters: { id: { eq: 292 } }) {
        ...NavSections
      }
      lvxLogo: images(filters: { name: { eq: "LVX_Preferred_Logo" } }) {
        data {
          id
          attributes {
            desktop {
              ...CmsUploadFileEntityResponse
            }
            mobile {
              ...CmsUploadFileEntityResponse
            }
          }
        }
      }
      supportDrawer: sections(filters: { id: { eq: 293 } }) {
        ...NavSections
      }
      supportRecipientEmails(filters: { location: { eq: "JAKARTA" } }) {
        data {
          id
          attributes {
            reason
            location
            emails {
              id
              language
              email
              hotel {
                data {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default Support;
